import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import '@fontsource/life-savers';
import '@fontsource/merriweather';
import '../styles/main.scss';
import './layout.css';
import { Container, Nav, Navbar } from 'react-bootstrap';

const Layout = ({ title, description, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          defaultDescription: description
          siteUrl
          twitterUsername
          # ogImage
        }
      }
      allGraphCmsPage(filter: {nav: {eq: true}}) {
        nodes {
          id
          slug
          title
        }
      }
      allGraphCmsBlogPost {
        totalCount
      }
    }
  `)

  const nav = [
    {
      name: 'Home',
      path: '/'
    },
    {
      name: 'Books',
      path: '/books'
    },
    {
      name: 'Blog',
      path: '/blog',
      if: data.allGraphCmsBlogPost.totalCount > 0
    }
  ];

  const allNav = nav.concat(data.allGraphCmsPage.nodes.map((node) => ({
    name: node.title,
    path: `/${node.slug}`
  })));

  let mainTitle = data.site.siteMetadata.title;
  if (title) {
    mainTitle = `${title} | ${data.site.siteMetadata.title}`;
  } else {
    title = data.site.siteMetadata.title;
  }

  if (!description) {
    description = data.site.siteMetadata.defaultDescription;
  }

  return (
    <div id="site">
      <GatsbySeo
        title={mainTitle}
        description={description}
        language="en"

        openGraph={{
          url: data.site.siteMetadata.siteUrl,
          title: title,
          description,
          // images: [
          //   {
          //     url: data.site.siteMetadata.ogImage
          //   }
          // ],
          site_name: data.site.siteMetadata.siteTitle,
          locale: "enGB"
        }}
        twitter={{
          handle: data.site.siteMetadata.twitterUsername,
          site: data.site.siteMetadata.twitterUsername,
          cardType: 'summary_large_image'
        }}
      />

      <Navbar bg="primary" variant="dark" expand="sm">
        <Container>
          <Navbar.Brand className="font-primary">{data.site.siteMetadata.title}</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="me-auto">
              {
                allNav.map((link) => {
                  if (link.if === false) {
                    return null
                  }
                  return <Link key={link.path} className="nav-link" activeClassName="active" to={link.path}>{link.name}</Link>
                })
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <main>
        {children}
      </main>

      <footer className="bg-light">
        <Container className="text-center py-3 fw-lighter">
          &copy; {data.site.siteMetadata.title} {(new Date()).getFullYear()}
        </Container>
      </footer>
    </div>
  )
}

export default Layout
